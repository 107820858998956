import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import AlertBox from '../../../../components/AlertBox/AlertBox';
import Button from '../../../../components/Button/Button';
import FormActions from '../../../../components/Form/FormActions';
import Input from '../../../../components/Form/Input';
import InputRow from '../../../../components/Form/InputRow';
import Modal from '../../../../components/Modal/Modal';
import useAppState from '../../../../hooks/useAppState/useAppState';
import { BookieDetail } from '../../../../types/models';
import styles from './BookmakerLink.module.css';

type Props = {
  bookieId: string;
  bookieDetail: BookieDetail;
  onDismiss: () => void;
};

type FormValues = {
  username: string;
  password: string;
};

export default function BookmakerLogin({
  bookieId,
  bookieDetail,
  onDismiss,
}: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();
  const { axios } = useAppState();
  const [errorMessage, setErrorMessage] = useState('');
  const loginBookie = useMutation<unknown, unknown, FormValues>(
    async (data) => {
      await axios.post('/v1/userbookies', {
        action: 'link-do',
        bookieId,
        data,
        tid: v4(),
      });
    },
    {
      onSuccess: () => {
        toast.info(`We're processing your login to ${bookieDetail.name}.`);
        onDismiss();
      },
      onError: () => {
        setErrorMessage('Something went wrong.');
      },
    }
  );

  const login: SubmitHandler<FormValues> = async (data) => {
    await loginBookie.mutate(data);
  };

  return (
    <Modal title="Login" onDismiss={onDismiss}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.imgContainer}>
            <img
              src={bookieDetail.logo}
              alt={bookieDetail.name}
              className={styles.img}
            />
          </div>
          <span className={styles.title}>Login to {bookieDetail.name}</span>
        </div>
        <form onSubmit={handleSubmit(login)}>
          <InputRow label="Username" errorText={errors.username?.message}>
            <Input
              id="Username"
              {...register('username', { required: 'Required' })}
            />
          </InputRow>
          <InputRow label="Password" errorText={errors.password?.message}>
            <Input
              id="Password"
              type="password"
              {...register('password', { required: 'Required' })}
            />
          </InputRow>
          {errorMessage ? (
            <AlertBox type="error" message={errorMessage} />
          ) : null}
          <FormActions>
            <Button type="submit" disabled={isSubmitting}>
              Login
            </Button>
          </FormActions>
        </form>
      </div>
    </Modal>
  );
}
