import Container from '../../components/Container/Container';
import styles from './PrivacyPolicy.module.css';

export default function PrivacyPolicy() {
  return (
    <div className={styles.container}>
      <Container>
        <h2>Privacy Policy</h2>
        <hr />
        <h3>1. Introduction</h3>
        <p>
          1.1 “We”, “us”, “our” and “BC” means Bookies Card Pty Ltd ACN 643 810
          708.
        </p>
        <p>
          1.2 We collect personal information in the course of doing business in
          Australia. This privacy policy has been developed to ensure that such
          information is handled appropriately.
        </p>
        <p>
          1.3 We are committed to complying with the Privacy Act 1988 (Cth) (
          <strong>Privacy Act</strong>) in relation to all personal information
          we collect. Our commitment is demonstrated in this policy. The Privacy
          Act incorporates the Australian Privacy Principles (
          <strong>APPs</strong>). The APPs set out the way that personal
          information must be treated
        </p>
        <p>
          1.4 By providing your details, you consent to us collecting, holding,
          using and disclosing your personal information in accordance with this
          policy.
        </p>
        <h4>Who does the privacy policy apply to?</h4>
        <p>
          1.5 This policy applies to any person in relation to whom we currently
          hold, or may in the future collect, personal information. Broadly, we
          collect personal information from our customers, prospective customers
          and other individuals that contact us with enquiries, prospective and
          current contractors, consultants and agents, prospective employees and
          third parties and their employees with whom we or our customers have a
          business or commercial relationship.
        </p>
        <p>
          1.6 This policy does not apply to acts and practices in relation to
          ‘employee records’ of our current and former employees, as these are
          exempt from the Privacy Act.
        </p>
        <h4>What information does the privacy policy apply to?</h4>
        <p>
          1.7 This policy applies to personal information (including sensitive
          information). In broad terms, “personal information” is information or
          opinions relating to a particular individual who can be identified.
        </p>
        <p>
          1.8 Information is not personal information where the information
          cannot be linked to an identifiable individual.
        </p>
        <h4>Anonymity and pseudonymity</h4>
        <p>
          1.9 We will take reasonable steps to destroy or de-identify personal
          information if that information is no longer needed for the purposes
          for which we are authorised to use it.
        </p>
        <p>
          1.10 If you want to use a pseudonym or remain anonymous when dealing
          with us, you should notify us and we will try to accommodate your
          request, subject to our ability to interact with you without having
          your personal information.
        </p>
        <p>
          1.11 You do not have to provide your personal information to us but if
          you do not provide the information requested by us, we may not be able
          to provide you with our products or services or otherwise engage with
          you.
        </p>
        <h3>2. What kinds of personal information do we collect and hold?</h3>
        <h4>Personal information</h4>
        <p>
          2.1 The personal information we may collect differs, depending on the
          nature of our interaction with you.
        </p>
        <p>
          2.2 If you are a customer or prospective customer (or an individual
          representative of, or individual otherwise involved with, such), we
          may collect and hold personal information about you, which may
          include:
        </p>
        <ol type="a">
          <li>sensitive information;</li>
          <li>contact information;</li>
          <li>financial and credit information;</li>
          <li>date and place of birth;</li>
          <li>
            your history of purchases and use of our products and services;
          </li>
          <li>details of enquiries or complaints that you make;</li>
          <li>banking details;</li>
          <li>credit card or payment details;</li>
          <li>
            government related identifiers, such as passport numbers and
            driver’s licence numbers; and
          </li>
          <li>
            any other personal information required to provide products and
            services.
          </li>
        </ol>
        <p>
          2.3 If you are a prospective or current contractor, consultant or
          agent, or prospective employee or a third party or their employee with
          whom we or our customers have a business or commercial relationship,
          we may collect and hold personal information about you, which may
          include:
        </p>
        <ol type="a">
          <li>sensitive information;</li>
          <li>contact information;</li>
          <li>date of birth;</li>
          <li>employment history;</li>
          <li>tax file number information;</li>
          <li>insurance information and claims history;</li>
          <li>credit information;</li>
          <li>licence details;</li>
          <li>education details;</li>
          <li>driving history;</li>
          <li>banking details;</li>
          <li>
            government related identifiers, such as passport numbers and
            driver’s licence numbers;
          </li>
          <li>
            any other personal information required to engage you as our
            contractor, consultant, agent, employee or in any other capacity;
            and
          </li>
          <li>
            records of contact and details of enquiries, conversations or
            correspondence made or received with you.
          </li>
        </ol>
        <p>
          2.4 If you are a user of our website, we may also collect information
          (that may include personal information) about how you access, use and
          interact with our website. This information may include:
        </p>
        <ol type="a">
          <li>
            the location from which you have come to the site and the pages you
            have visited;
          </li>
          <li>
            technical data, which may include IP address, the types of devices
            you are using to access the website, device attributes, browser
            type, language and operating system; and
          </li>
          <li>
            page clicks, time spent and other automatically collected meta-data.
          </li>
        </ol>
        <h4>Sensitive information</h4>
        <p>
          2.5 ‘Sensitive information’ is a subset of personal information and
          includes personal information that may have serious ramifications for
          the individual concerned if used inappropriately
        </p>
        <p>
          2.6 We may collect sensitive information about prospective or current
          contractors, consultants, agents or prospective employees, including:
        </p>
        <ol type="a">
          <li>
            health information (including but not limited to drug and alcohol
            testing results);
          </li>
          <li>criminal history;</li>
          <li>membership of professional or trade associations; and</li>
          <li>membership of trade unions.</li>
        </ol>
        <p>
          2.7 We generally do not collect sensitive information from our current
          or prospective customers, with the exception of biometric information
          that can be used for the purpose of automated biometric verification
          or biometric identification.
        </p>
        <p>
          2.8 In any case, we will not collect sensitive information without the
          individual’s consent to whom the information relates unless permitted
          under the Privacy Act.
        </p>
        <h3>3. How do we collect personal information?</h3>
        <p>
          3.1 We may collect personal information directly from the individual
          concerned, for example via our website (including through the use of
          cookies), online forms, telephone calls, face-to-face meetings and
          email or from third parties such as:
        </p>
        <ol type="a">
          <li>our employees, contractors, consultants and agents;</li>
          <li>your representatives and advisers;</li>
          <li>banks and financial institutions;</li>
          <li>your current and previous employers;</li>
          <li>trade referees;</li>
          <li>
            government bodies (such as relevant departments, regulatory
            authorities etc.);
          </li>
          <li>paid search providers;</li>
          <li>through marketing and business development events; and</li>
          <li>referrals from individuals or other entities.</li>
        </ol>
        <h3>4. How do we hold personal information?</h3>
        <p>4.1 Our usual approach to holding personal information includes:</p>
        <ol type="a">
          <li>
            physically, in paper files stored securely at our premises; and
          </li>
          <li>
            electronically, in computer system and databases (including cookies)
            either operated by us or our external service providers.
          </li>
        </ol>
        <p>
          4.2 We implement and maintain processes and security measures to
          protect personal information which we hold from misuse, interference
          or loss, and from unauthorised access, modification or disclosure.
        </p>
        <p>4.3 Some of these processes and systems include:</p>
        <ol type="a">
          <li>
            using security cards or access codes to access areas that contain
            personal information;
          </li>
          <li>using secure servers to store personal information;</li>
          <li>
            using unique usernames, passwords and other protections on systems
            that can access personal information;
          </li>
          <li>
            arranging for our employees to complete training about information
            security;
          </li>
          <li>holding certain sensitive documents securely; and</li>
          <li>monitoring and reviewing our policies.</li>
        </ol>
        <p>
          4.4 Though we take these measures to keep your information secure, we
          cannot guarantee that your information will remain secure or that it
          will only be disclosed in a manner consistent with this policy.
        </p>
        <h3>
          5. Why do we collect, hold, use or disclose personal information?
        </h3>
        <p>
          5.1 We take reasonable steps to hold, use and disclose personal
          information for the primary purpose for which we collect it. The
          primary purpose for which we collect information varies depending on
          the individual that we are collecting the information from but is
          generally as follows:
        </p>
        <ol type="a">
          <li>
            in the case of customers and prospective customers (or an individual
            representative of, or individual otherwise involved with, such) to
            provide our products and services, for example:
            <ol type="i">
              <li>for payment purposes;</li>
              <li>
                to keep records of transactions to assist in future enquiries
                and improve our website, products and services;
              </li>
              <li>to provide support relating to our products and services;</li>
              <li>to keep records of your content and email preferences;</li>
              <li>
                to provide you with updates that are relevant to you or your
                business.
              </li>
              <li>to manage and perform any contract entered into with you;</li>
              <li>
                to help us identify and develop products and services that may
                interest you;
              </li>
              <li>to maintain and enhance our relationship with you;</li>
              <li>to conduct market or customer satisfaction research;</li>
              <li>to conduct promotions and invite you to events;</li>
              <li>
                to make you aware of new and additional products, services and
                opportunities;
              </li>
              <li>
                to improve our products and services and better understand your
                needs;
              </li>
              <li>to record and analyse how you use our website;</li>
              <li>to send you newsletters;</li>
              <li>
                to ensure compliance with legislative and regulatory
                requirements; and
              </li>
              <li>to contact you.</li>
            </ol>
          </li>
          <li>
            in the case of other persons that contact us (including users of our
            website), to assist us to respond to your enquiries or complaints;
          </li>
          <li>
            in the case of a current contractor, consultant, agent, or third
            parties and their employees with whom we or our customers have a
            business or commercial relationship to assist us in providing our
            products and services;
          </li>
          <li>
            in the case of a prospective contractor, consultant, agent, employee
            or a third party and their employees with whom we or our customers
            may wish to have a business or commercial relationship with , to
            assess your suitability for employment or engagement; and
          </li>
          <li>
            to protect the copyright, trade marks, legal rights, property and
            safety of the products and services, website and customers of BC and
            our related entities.
          </li>
        </ol>
        <p>
          5.2 Personal information may also be used or disclosed by us for
          secondary purposes that are within your reasonable expectations and
          that are related to the primary purpose of collection.
        </p>
        <p>5.3 We may disclose personal information to:</p>
        <ol type="a">
          <li>
            our contractors, subcontractors, suppliers, consultants, agents,
            employees, officers or other third parties with whom we or our
            customers have a business or commercial relationship;
          </li>
          <li>
            government bodies (such as relevant departments, regulatory
            authorities etc.);
          </li>
          <li>emergency service providers and law enforcement agencies;</li>
          <li>
            our external auditors, brokers and insurance providers and legal and
            other advisers;
          </li>
          <li>employers of individuals;</li>
          <li>banks;</li>
          <li>your advisers;</li>
          <li>
            third parties considering the acquisition of the whole or part of
            our assets or business, or that acquire the whole or part of our
            assets or business; and
          </li>
          <li>our related entities.</li>
        </ol>
        <p>
          5.4 We will only disclose personal information to third parties if:
        </p>
        <ol type="a">
          <li>
            we are required or authorised by law to do so, including under a
            regulation, court order, subpoena, warrant, in the course of a legal
            proceeding or in response to a request from a law enforcement
            agency;
          </li>
          <li>
            we have received express consent to the disclosure, or consent may
            be reasonably inferred from the circumstances; or
          </li>
          <li>
            we are otherwise permitted to disclose the information under the
            Privacy Act.
          </li>
        </ol>
        <p>
          5.5 You acknowledge that if we disclose personal information to
          government bodies (such as relevant departments, regulatory
          authorities etc.), the collection, use and disclosure of that personal
          information may be regulated by other relevant legislation such as the{' '}
          <i>Right to Information Act 2009</i> (Qld).
        </p>

        <h3>6. Will we disclose personal information outside Australia?</h3>
        <p>
          6.1 We generally do not disclose information outside of Australia,
          unless specifically requested to do so by you. In some cases, we may
          indirectly disclose personal information overseas through disclosures
          to our contractors and service providers that have data servers
          located overseas.
        </p>
        <p>
          6.2 Where we disclose your personal information to parties located
          overseas (or which have data centres located in other countries), we
          take reasonable steps to ensure that those parties will handle the
          personal information in accordance with the Australian Privacy
          Principles. We are not required to take such steps if we believe that
          the overseas recipient is already subject to a law that has the effect
          of protecting personal information in a substantially similar way to
          the relevant law in Australia, or with your consent.
        </p>

        <h3>7. How do we manage your credit information?</h3>
        <h4>What kinds of credit information may we collect?</h4>
        <p>
          7.1 In the course of offering and providing products and services, we
          may collect and hold the following kinds of credit information:
        </p>
        <ol type="a">
          <li>identification information;</li>
          <li>information about any credit that has been provided to you;</li>
          <li>your repayment history;</li>
          <li>information about your overdue payments;</li>
          <li>
            if terms and conditions of your credit arrangements are varied;
          </li>
          <li>
            if any court proceedings are initiated against you in relation to
            your credit activities;
          </li>
          <li>
            information about any bankruptcy or debt agreements involving you;
          </li>
          <li>
            any publicly available information about your credit worthiness; and
          </li>
          <li>
            any information about you where you may have fraudulently or
            otherwise committed a serious credit infringement.
          </li>
        </ol>
        <p>
          7.2 We may also collect personal information that may affect a
          person’s credit worthiness from other credit providers (e.g. banks)
          that collect that information from credit reporting bodies. The kinds
          of personal information we collect may include any of those kinds of
          personal information outlined in section 2 of this policy.
        </p>
        <h4>How and when do we collect credit information?</h4>
        <p>
          7.3 In most cases, we will only collect credit information if it is
          relevant to provide our products and services.
        </p>
        <p>
          7.4 We may collect credit information directly or from third parties
          including:
        </p>
        <ol type="a">
          <li>
            government bodies (such as relevant departments, regulatory
            authorities etc.);
          </li>
          <li>banks, credit reporting bodies and other credit providers;</li>
          <li>other individuals and entities via referrals; and</li>
          <li>your suppliers and creditors.</li>
        </ol>
        <p>
          7.5 We do not collect and hold credit information from credit
          reporting bodies unless it is incidentally collected in providing our
          products and services.
        </p>
        <h4>How do we store and hold the credit information?</h4>
        <p>
          7.6 We store and hold credit information in the same manner as
          outlined in section 4 of this policy
        </p>
        <h4>Why do we collect the credit information?</h4>
        <p>
          7.7 Our usual purpose for collecting, holding, using and disclosing
          credit information is to enable us to provide our products and
          services.
        </p>
        <p>7.8 We may also collect the credit information:</p>
        <ol type="a">
          <li>to process payments;</li>
          <li>to assess eligibility for credit; and</li>
          <li>for other purposes incidental to our services.</li>
        </ol>
        <h4>Overseas disclosure of the credit information</h4>
        <p>
          7.9 We may disclose your credit information to entities outside of
          Australia in the same manner as outlined in section 6 of this policy.
        </p>
        <h4>
          How can I access my credit information, correct errors or make a
          complaint?
        </h4>
        <p>
          7.10 You can seek access to and request a correction to your credit
          information or complain about a breach of your privacy in the same
          manner as set out in section 8 of this policy. We will deal with a
          complaint in the same manner as set out in section 8 of this policy.
        </p>

        <h3>
          8. How do you make complaints and access and correct your personal
          information or credit information?
        </h3>
        <p>
          8.1 It is important that the information we hold about you is
          up-to-date. You should contact us if your personal information
          changes.
        </p>

        <h4>Access to information and correcting personal information</h4>
        <p>
          8.2 You may request access to the personal information held by us or
          ask us for your personal information to be corrected by using the
          contact details in this section.
        </p>
        <p>
          8.3 We will grant you access to your personal information as soon as
          possible, subject to the request circumstances
        </p>
        <p>
          8.4 In keeping with our commitment to protect the privacy of personal
          information, we may not disclose personal information to you without
          proof of identity.
        </p>
        <p>8.5 We may deny access to personal information if:</p>
        <ol type="a">
          <li>the request is unreasonable;</li>
          <li>
            providing access would have an unreasonable impact on the privacy of
            another person;
          </li>
          <li>
            providing access would pose a serious and imminent threat to the
            life or health of any person; or
          </li>
          <li>there are other legal grounds to deny the request.</li>
        </ol>
        <p>
          8.6 We may charge a fee for reasonable costs incurred in responding to
          an access request. The fee (if any) will be disclosed prior to it
          being levied.
        </p>
        <p>
          8.7 If the personal information we hold is not accurate, complete and
          up-to-date, we will take reasonable steps to correct it where it is
          appropriate to do so.
        </p>

        <h4>Complaints</h4>
        <p>
          8.8 If you would like to complain about an interference with your
          privacy, then you must follow the following process:
        </p>
        <ol type="a">
          <li>
            The complaint must first be made to us in writing, using the contact
            details in this section. We will have a reasonable time to respond
            to the complaint.
          </li>
          <li>
            If the privacy issue cannot be resolved, you may take your complaint
            to the Office of the Australian Information Commissioner.
          </li>
        </ol>

        <h4>Who to contact</h4>
        <p>
          8.9 A person may make a complaint or request to access or correct
          personal information about them held by us. Such a request must be
          made in writing to the following address:
        </p>
        <blockquote>
          Attention: Privacy Officer
          <br />
          PO Box 33
          <br />
          Broadmeadow New South Wales 2292
        </blockquote>

        <h3>9. Changes to the policy</h3>
        <p>
          9.1 We may update, modify or remove this policy at any time without
          prior notice.
        </p>
        <p>
          9.2 This policy is effective 12th October 2021. If you have any
          comments on the policy, please contact the Privacy Officer with the
          contact details in section 8 of this policy.
        </p>
      </Container>
    </div>
  );
}
