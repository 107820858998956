import QueryString from 'qs';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppStateProvider from '../../hooks/useAppState/AppStateProvider';
import Account from '../Account/Account';
import EMLDisclaimer from '../EMLDisclaimer/EMLDisclaimer';
import Home from '../Home/Home';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import ForgotPasswordModal from './ForgotPasswordModal/ForgotPasswordModal';
import Layout from './Layout/Layout';
import LoginModal from './LoginModal/LoginModal';
import SignupModal from './SignupModal/SignupModal';

export default function App() {
  const location = useLocation();
  const qs = QueryString.parse(location.search, { ignoreQueryPrefix: true });

  function renderModal() {
    switch (qs.action) {
      case 'login':
        return <LoginModal />;
      case 'signup':
        return <SignupModal />;
      case 'forgot':
        return <ForgotPasswordModal />;
      default:
        return null;
    }
  }

  return (
    <AppStateProvider>
      <>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/account" component={Account} />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/eml-disclaimer" component={EMLDisclaimer} />
          </Switch>
        </Layout>
        {renderModal()}
        <ToastContainer position="bottom-right" newestOnTop />
      </>
    </AppStateProvider>
  );
}
