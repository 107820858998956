import clsx from 'clsx';
import { forwardRef } from 'react';
import styles from './Checkbox.module.css';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
};

const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ id, label, className, ...rest }, ref) => (
    <div className={styles.container}>
      <input
        type="checkbox"
        id={id}
        ref={ref}
        className={clsx(styles.checkbox, className)}
        {...rest}
      />
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
    </div>
  )
);

export default Checkbox;
