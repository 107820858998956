import Auth, { CognitoUser } from '@aws-amplify/auth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal/Modal';
import InputRow from '../../../components/Form/InputRow';
import Input from '../../../components/Form/Input';
import FormActions from '../../../components/Form/FormActions';
import Button from '../../../components/Button/Button';
import useAppState from '../../../hooks/useAppState/useAppState';
import styles from './LoginModal.module.css';
import Icon from '../../../components/Icon/Icon';

type FormValues = {
  username: string;
  password: string;
};

export default function LoginModal() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();
  const { setCurrentUser } = useAppState();

  const login: SubmitHandler<FormValues> = async (data) => {
    try {
      const cognitoUser: CognitoUser = await Auth.signIn({
        username: data.username,
        password: data.password,
        validationData: {
          Name: 'recaptchaToken',
          recaptchaToken: '',
        },
      });
      setCurrentUser(cognitoUser);
      toast.success('You have logged in.');
      history.push('/account');
    } catch (error) {
      setError('password', {
        message: 'Your username or password is incorrect',
      });
    }
  };

  return (
    <Modal title="Login" onDismiss={() => history.push({ search: '' })}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Icon name="logo" />
        </div>
        <form onSubmit={handleSubmit(login)}>
          <InputRow label="Username/email" errorText={errors.username?.message}>
            <Input
              id="Username/email"
              autoComplete="username"
              autoFocus
              {...register('username', { required: 'Required' })}
            />
          </InputRow>
          <InputRow label="Password" errorText={errors.password?.message}>
            <Input
              type="password"
              id="Password"
              autoComplete="password"
              {...register('password', { required: 'Required' })}
            />
          </InputRow>
          <FormActions>
            <Button type="submit" disabled={isSubmitting}>
              Login
            </Button>
          </FormActions>
        </form>
        <span className={styles.disclaimer}>
          <Link to={{ search: 'action=forgot' }}>Forgot your password?</Link>
        </span>
      </div>
    </Modal>
  );
}
