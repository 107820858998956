import Auth from '@aws-amplify/auth';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';
import useAppState from '../../../hooks/useAppState/useAppState';
import ChangePasswordModal from './ChangePasswordModal/ChangePasswordModal';
import PersonalDetailsModal from './PersonalDetailsModal/PersonalDetailsModal';

import styles from './SettingsModal.module.css';

export default function SettingsModal({
  onRequestCard,
}: {
  onRequestCard: () => void;
}) {
  const history = useHistory();
  const { setCurrentUser, setShowSettings } = useAppState();
  const [showPersonalDetails, setShowPersonalDetails] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  useEffect(() => {
    document.body.classList.add('hide-overflow--nested');

    return () => {
      document.body.classList.remove('hide-overflow--nested');
    };
  }, []);

  async function logout() {
    await Auth.signOut();
    setCurrentUser(undefined);
    toast.success('You have logged out.');
    setShowSettings(false);
    history.push('/');
  }

  return createPortal(
    <div className={styles.container}>
      <div className={styles.header}>
        <Icon name="logo" className={styles.logo} />
        <Button theme="none" onClick={() => setShowSettings(false)}>
          <Icon name="cross" />
        </Button>
      </div>
      <div className={styles.content}>
        <h2 className={styles.title}>Settings</h2>
        <ul className={styles.list}>
          <li>
            <Button
              theme="none"
              className={styles.button}
              contentClassName={styles.buttonContent}
              onClick={onRequestCard}
            >
              <span className={styles.row}>
                <img
                  src="/bookiescard-straight.png"
                  alt="Card"
                  className={styles.img}
                />
                <span>Request Bookies Card</span>
              </span>
              <Icon name="chevron" className={styles.chevron} />
            </Button>
          </li>
          <li>
            <Button
              theme="none"
              className={styles.button}
              contentClassName={styles.buttonContent}
              onClick={() => setShowPersonalDetails(true)}
            >
              <span>Personal Details</span>
              <Icon name="chevron" className={styles.chevron} />
            </Button>
          </li>
          <li>
            <Button
              theme="none"
              className={styles.button}
              contentClassName={styles.buttonContent}
              onClick={() => setShowChangePassword(true)}
            >
              <span>Change Password</span>
              <Icon name="chevron" className={styles.chevron} />
            </Button>
          </li>
          <li>
            <Button
              theme="none"
              className={styles.button}
              contentClassName={styles.buttonContent}
            >
              <span>Contact Us</span>
              <Icon name="chevron" className={styles.chevron} />
            </Button>
          </li>
          <li>
            <Button
              theme="none"
              className={styles.button}
              contentClassName={styles.buttonContent}
              onClick={logout}
            >
              <span>Log Out</span>
              <Icon name="chevron" className={styles.chevron} />
            </Button>
          </li>
        </ul>
      </div>
      {showPersonalDetails ? (
        <PersonalDetailsModal onDismiss={() => setShowPersonalDetails(false)} />
      ) : null}
      {showChangePassword ? (
        <ChangePasswordModal onDismiss={() => setShowChangePassword(false)} />
      ) : null}
    </div>,
    document.body
  );
}
