import clsx from 'clsx';
import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SignupContext from './SignupContext';
import styles from './SignupModal.module.css';

export default function SignupProgress() {
  const location = useLocation();
  const { data } = useContext(SignupContext);

  const page1Completed =
    data?.firstName &&
    data?.lastName &&
    data?.dateOfBirth &&
    data?.mobile &&
    data?.email;
  const page2Completed =
    data?.address && data?.suburb && data?.postcode && data?.state;

  return (
    <div className={styles.progress}>
      <Link
        to="/"
        className={clsx(styles.progressLink, {
          [styles.selected]: location.pathname === '/',
          [styles.completed]: page1Completed,
        })}
      >
        {page1Completed ? '✓' : 1}
      </Link>
      <Link
        to="/address"
        className={clsx(styles.progressLink, {
          [styles.selected]: location.pathname === '/address',
          [styles.completed]: page2Completed,
        })}
      >
        {page2Completed ? '✓' : 2}
      </Link>
      <Link
        to="/account"
        className={clsx(styles.progressLink, {
          [styles.selected]: location.pathname === '/account',
        })}
      >
        3
      </Link>
    </div>
  );
}
