import { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import FormActions from '../../../components/Form/FormActions';
import Input from '../../../components/Form/Input';
import InputRow from '../../../components/Form/InputRow';
import Select from '../../../components/Form/Select';
import SignupContext, { SignupForm } from './SignupContext';

export default function AddressDetails() {
  const history = useHistory();
  const { data, setData } = useContext(SignupContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignupForm>({
    defaultValues: data,
  });

  const submitHandler: SubmitHandler<SignupForm> = (formValues) => {
    setData?.((prev) => ({ ...prev, ...formValues }));
    history.push('/account');
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <InputRow label="Address" errorText={errors.address?.message}>
        <Input
          id="Address"
          placeholder="Enter your address"
          autoComplete="street-address"
          autoFocus
          {...register('address', { required: 'Required' })}
        />
      </InputRow>
      <InputRow label="Suburb" errorText={errors.suburb?.message}>
        <Input
          id="Suburb"
          placeholder="Enter your suburb"
          autoComplete="address-level2"
          {...register('suburb', { required: 'Required' })}
        />
      </InputRow>
      <InputRow label="State" errorText={errors.state?.message}>
        <Select
          id="State"
          autoComplete="address-level1"
          options={[
            {
              label: 'Enter your state',
              value: '',
            },
            {
              label: 'New South Wales',
              value: 'NSW',
            },
            {
              label: 'Queensland',
              value: 'QLD',
            },
            {
              label: 'Victoria',
              value: 'VIC',
            },
            {
              label: 'Tasmania',
              value: 'TAS',
            },
            {
              label: 'Western Australia',
              value: 'WA',
            },
            {
              label: 'South Australia',
              value: 'SA',
            },
          ]}
          {...register('state', { required: 'Required' })}
        />
      </InputRow>
      <InputRow label="Postcode" errorText={errors.postcode?.message}>
        <Input
          id="Postcode"
          placeholder="Enter your postcode"
          autoComplete="postal-code"
          {...register('postcode', { required: 'Required' })}
        />
      </InputRow>
      <FormActions>
        <Button type="submit" disabled={isSubmitting}>
          Next
        </Button>
      </FormActions>
    </form>
  );
}
