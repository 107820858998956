import { BookieDetail } from '../types/models';

const bookieDetail: Record<string, BookieDetail> = {
  'b3c27b0f-e288-4473-b0c7-0a2fda022372': {
    name: 'Real Bookie',
    logo: '/bookies/realbookie.svg',
  },
  '2f98efd6-15a9-47ef-a5fe-d8e214d33010': {
    name: 'Bet Deluxe',
    logo: '/bookies/betdeluxe.svg',
  },
  '80f91e92-760b-466e-aa60-50cd1264289d': {
    name: 'TexBet',
    logo: '/bookies/texbet.svg',
  },
  'b00c4d37-7694-44d9-8903-147f4bb06a2e': {
    name: 'Rob Waterhouse',
    logo: '/bookies/rwat.svg',
  },
};

export default bookieDetail;
