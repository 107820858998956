import Auth from '@aws-amplify/auth';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Card from '../../components/Card/Card';
import useAppState from '../../hooks/useAppState/useAppState';
import { BookieCard, UserBookie } from '../../types/models';
import AvailableBookmakers from './AvailableBookmakers/AvailableBookmakers';
import MyBookmakers from './MyBookmakers/MyBookmakers';
import Summary from './Summary/Summary';
import Transfer from './Transfer/Transfer';
import Button from '../../components/Button/Button';
import RequestCardModal from './RequestCardModal/RequestCardModal';
import Transactions from './Transactions/Transactions';
import styles from './Account.module.css';
import SettingsModal from './SettingsModal/SettingsModal';
import AccountContext from './AccountContext';

export default function Account() {
  const history = useHistory();
  const { currentUser, axios, showSettings } = useAppState();
  const queryClient = useQueryClient();
  const [showRequestCard, setShowRequestCard] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'bookmakers' | 'transactions'>(
    'bookmakers'
  );
  const cards = useQuery<BookieCard[]>('Cards', async () => {
    const response = await axios.get('/v1/cards');
    return response.data;
  });
  const myBookies = useQuery<UserBookie[]>('UserBookies', async () => {
    const response = await axios.get('/v1/userbookies');
    return response.data;
  });

  useEffect(() => {
    let ws: WebSocket;

    const eventListener = (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);
        toast[data.status === 'success' ? 'success' : 'error'](data.message);
        queryClient.refetchQueries();
      } catch (error) {
        // Ignore
      }
    };

    async function init() {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      ws = new WebSocket(
        `${process.env.REACT_APP_SOCKET_URL}?token=${idToken.getJwtToken()}`
      );

      ws.addEventListener('message', eventListener);
    }

    init();

    return () => {
      ws.removeEventListener('message', eventListener);
    };
  }, [queryClient]);

  useEffect(() => {
    if (!currentUser) {
      history.push('/');
    }
  }, [currentUser, history]);

  return (
    <AccountContext.Provider
      value={{
        cards: cards.data || [],
        myBookies: myBookies.data || [],
      }}
    >
      <>
        <div className={styles.container}>
          <div className={styles.main}>
            <div className={styles.box}>
              <Summary />
            </div>
            <div className={styles.tabs}>
              <button
                type="button"
                className={clsx(styles.tab, {
                  [styles.selected]: selectedTab === 'bookmakers',
                })}
                onClick={() => setSelectedTab('bookmakers')}
              >
                Bookmakers
              </button>
              <button
                type="button"
                className={clsx(styles.tab, {
                  [styles.selected]: selectedTab === 'transactions',
                })}
                onClick={() => setSelectedTab('transactions')}
              >
                Transactions
              </button>
            </div>
            {selectedTab === 'transactions' ? (
              <div className={styles.box}>
                <Transactions />
              </div>
            ) : (
              <>
                <div className={styles.box}>
                  <MyBookmakers />
                </div>
                <div className={styles.box}>
                  <AvailableBookmakers />
                </div>
              </>
            )}
          </div>
          <div className={styles.secondary}>
            <div className={styles.margin}>
              <Card>
                <div className={styles.requestCard}>
                  <div className={styles.imgContainer}>
                    <img
                      src="/bookiescard-straight.png"
                      alt="Card"
                      className={styles.img}
                    />
                  </div>
                  <span className={styles.title}>
                    Request your Bookies Card
                  </span>
                  <Button onClick={() => setShowRequestCard(true)}>
                    Request Card
                  </Button>
                </div>
              </Card>
            </div>
            {!myBookies.isLoading ? (
              <div className={styles.box}>
                <Transfer />
              </div>
            ) : null}
          </div>
        </div>
        {showRequestCard ? (
          <RequestCardModal onDismiss={() => setShowRequestCard(false)} />
        ) : null}
        {showSettings ? (
          <SettingsModal onRequestCard={() => setShowRequestCard(true)} />
        ) : null}
      </>
    </AccountContext.Provider>
  );
}
