import { createContext } from 'react';
import { BookieCard, UserBookie } from '../../types/models';

const AccountContext = createContext<{
  cards: BookieCard[];
  myBookies: UserBookie[];
}>({
  cards: [],
  myBookies: [],
});

export default AccountContext;
