import { useContext } from 'react';
import Card from '../../../components/Card/Card';
import bookieDetail from '../../../utils/bookieDetail';
import format from '../../../utils/format';
import AccountContext from '../AccountContext';
import styles from './MyBookmakers.module.css';

export default function MyBookmakers() {
  const { myBookies } = useContext(AccountContext);

  return (
    <>
      <h2>My Bookmakers</h2>
      <div className={styles.list}>
        {myBookies.map((bookie) => {
          const bookieMap = bookieDetail[bookie.bookieId];

          return (
            <Card key={bookie.id}>
              <div className={styles.header}>
                <div className={styles.bookie}>
                  <img src={bookieMap.logo} alt={bookieMap.name} />
                  <span className={styles.name}>{bookieMap.name}</span>
                </div>
                <button type="button" className={styles.button}>
                  Download app
                </button>
              </div>
              <div className={styles.bookieContent}>
                <div>
                  <span className={styles.label}>Balance:</span>
                  <span className={styles.value}>{format.currency(0)}</span>
                </div>
                <div>
                  <span className={styles.label}>Withdrawable:</span>
                  <span className={styles.value}>{format.currency(0)}</span>
                </div>
              </div>
            </Card>
          );
        })}
      </div>
    </>
  );
}
