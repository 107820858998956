import dayjs from 'dayjs';
import Modal from '../../../../components/Modal/Modal';
import useAppState from '../../../../hooks/useAppState/useAppState';
import styles from './PersonalDetailsModal.module.css';

export default function PersonalDetailsModal({
  onDismiss,
}: {
  onDismiss: () => void;
}) {
  const { currentUser } = useAppState();
  const address = JSON.parse(currentUser?.attributes.address);

  return (
    <Modal title="Personal Details" onDismiss={onDismiss}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.title}>Personal Details</span>
        </div>
        <div className={styles.content}>
          <div className={styles.section}>
            <span className={styles.label}>Name</span>
            <span className={styles.value}>
              {[
                currentUser?.attributes?.given_name,
                currentUser?.attributes.family_name,
              ].join(' ')}
            </span>
          </div>
          <div className={styles.section}>
            <span className={styles.label}>Date of Birth</span>
            <span className={styles.value}>
              {dayjs(currentUser?.attributes.birthdate).format('DD/MM/YYYY')}
            </span>
          </div>
          <div className={styles.section}>
            <span className={styles.label}>Mobile</span>
            <span className={styles.value}>
              {currentUser?.attributes.phone_number}
            </span>
          </div>
          <div className={styles.section}>
            <span className={styles.label}>Address</span>
            <span className={styles.value}>
              {[
                address.address,
                address.suburb,
                address.state,
                address.postcode,
              ]
                .filter(Boolean)
                .join(', ')}
            </span>
          </div>
          <div className={styles.section}>
            <span className={styles.label}>Username</span>
            <span className={styles.value}>{currentUser?.getUsername()}</span>
          </div>
        </div>
        <span className={styles.disclaimer}>
          To update details - contact us on{' '}
          <a href="tel:1800000000">1800 000 000</a> or{' '}
          <a href="mailto:support@bookiecard.com">support@bookiecard.com</a>
        </span>
      </div>
    </Modal>
  );
}
