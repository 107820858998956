import clsx from 'clsx';
import { useState } from 'react';
import Icon from '../Icon/Icon';
import styles from './Accordion.module.css';

type Props = {
  items: {
    title: string;
    children: React.ReactNode;
  }[];
};

export default function Accordion({ items = [] }: Props) {
  const [expanded, setExpanded] = useState<number | undefined>();

  return (
    <div className={styles.accordion}>
      {items.map((item, index) => (
        <div
          className={clsx({
            [styles.expanded]: expanded === index,
          })}
          key={item.title}
        >
          <button
            type="button"
            onClick={() =>
              setExpanded((prev) => (prev !== index ? index : undefined))
            }
            className={styles.accordionButton}
          >
            <span>{item.title}</span>
            <Icon name="chevron" className={styles.chevron} />
          </button>
          <div className={styles.content}>{item.children}</div>
        </div>
      ))}
    </div>
  );
}
