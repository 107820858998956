import { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import AlertBox from '../../../components/AlertBox/AlertBox';
import Button from '../../../components/Button/Button';
import FormActions from '../../../components/Form/FormActions';
import Input from '../../../components/Form/Input';
import InputRow from '../../../components/Form/InputRow';
import SignupContext, { SignupForm } from './SignupContext';

export default function PersonalDetails() {
  const history = useHistory();
  const { data, setData } = useContext(SignupContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignupForm>({
    defaultValues: data,
  });

  const submitHandler: SubmitHandler<SignupForm> = (formValues) => {
    setData?.((prev) => ({ ...prev, ...formValues }));
    history.push('/address');
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <InputRow
        label="Name"
        errorText={errors.firstName?.message || errors.lastName?.message}
      >
        <Input
          id="Name"
          placeholder="Enter your first name"
          autoComplete="given-name"
          autoFocus
          {...register('firstName', { required: 'Required' })}
        />
        <Input
          placeholder="Enter your last name"
          autoComplete="family-name"
          {...register('lastName', { required: 'Required' })}
        />
      </InputRow>
      <InputRow label="Date of birth" errorText={errors.dateOfBirth?.message}>
        <Input
          id="Date of birth"
          placeholder="Enter your date of birth"
          type="date"
          autoComplete="bday"
          {...register('dateOfBirth', { required: 'Required' })}
        />
      </InputRow>
      <InputRow label="Mobile" errorText={errors.mobile?.message}>
        <Input
          id="Mobile"
          placeholder="Enter your mobile"
          type="number"
          prefix="+61"
          min={0}
          autoComplete="tel-local"
          {...register('mobile', { required: 'Required' })}
        />
      </InputRow>
      <InputRow label="Email" errorText={errors.email?.message}>
        <Input
          id="Email"
          placeholder="Enter your email address"
          type="email"
          autoComplete="email"
          {...register('email', { required: 'Required' })}
        />
      </InputRow>
      <AlertBox
        type="warning"
        message="Your email must be the same email used with available bookmakers to link them to Bookie Card."
      />

      <FormActions>
        <Button type="submit" disabled={isSubmitting}>
          Next
        </Button>
      </FormActions>
    </form>
  );
}
