import Auth from '@aws-amplify/auth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button/Button';
import FormActions from '../../../../components/Form/FormActions';
import Input from '../../../../components/Form/Input';
import InputRow from '../../../../components/Form/InputRow';
import Modal from '../../../../components/Modal/Modal';
import useAppState from '../../../../hooks/useAppState/useAppState';
import styles from './ChangePasswordModal.module.css';

type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export default function ChangePasswordModal({
  onDismiss,
}: {
  onDismiss: () => void;
}) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<FormValues>();
  const { currentUser } = useAppState();
  const matchPassword = watch('newPassword');

  const changePassword: SubmitHandler<FormValues> = async (data) => {
    try {
      await Auth.changePassword(
        currentUser,
        data.currentPassword,
        data.newPassword
      );
      toast.success('Your password has been changed.');
      onDismiss();
    } catch (error) {
      setError(
        // eslint-disable-next-line no-underscore-dangle
        error.__type === 'InvalidPasswordException'
          ? 'newPassword'
          : 'currentPassword',
        {
          message: error.message,
        }
      );
    }
  };

  return (
    <Modal title="Change Password" onDismiss={onDismiss}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.title}>Change Password</span>
          <span className={styles.subtitle}>
            Must have 8 characters, including upper &amp; lower case, numbers
            &amp; special characters
          </span>
        </div>
        <form onSubmit={handleSubmit(changePassword)}>
          <InputRow
            label="Current Password"
            errorText={errors.currentPassword?.message}
          >
            <Input
              id="Current Password"
              type="password"
              autoComplete="current-password"
              {...register('currentPassword', { required: 'Required' })}
            />
          </InputRow>
          <InputRow
            label="New Password"
            errorText={errors.newPassword?.message}
          >
            <Input
              type="password"
              id="New Password"
              autoComplete="new-password"
              {...register('newPassword', {
                required: 'Required',
                minLength: {
                  value: 8,
                  message: 'Password must have at least 8 characters',
                },
              })}
            />
          </InputRow>
          <InputRow
            label="Confirm Password"
            errorText={errors.confirmPassword?.message}
          >
            <Input
              type="password"
              id="Confirm Password"
              {...register('confirmPassword', {
                required: 'Required',
                validate: (value) =>
                  value === matchPassword || 'Passwords do not match',
              })}
            />
          </InputRow>
          <FormActions>
            <Button type="submit" disabled={isSubmitting}>
              Change Password
            </Button>
          </FormActions>
        </form>
      </div>
    </Modal>
  );
}
