import Auth from '@aws-amplify/auth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import InputRow from '../../../components/Form/InputRow';
import Input from '../../../components/Form/Input';
import FormActions from '../../../components/Form/FormActions';
import Button from '../../../components/Button/Button';
import styles from './ForgotPasswordModal.module.css';
import Icon from '../../../components/Icon/Icon';
import AlertBox from '../../../components/AlertBox/AlertBox';

type FormValues = {
  username: string;
  code: string;
  password: string;
};

export default function ForgotPasswordModal() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();
  const [resetting, setResetting] = useState(false);

  const sendVerification: SubmitHandler<FormValues> = async (data) => {
    try {
      await Auth.forgotPassword(data.username);
      setResetting(true);
    } catch (error) {
      setError('username', {
        message: error.message,
      });
    }
  };

  const reset: SubmitHandler<FormValues> = async (data) => {
    try {
      await Auth.forgotPasswordSubmit(data.username, data.code, data.password);
      toast.success('Your password has been changed. Please log in.');
    } catch (error) {
      setError(
        // eslint-disable-next-line no-underscore-dangle
        error.__type === 'ExpiredCodeException' ? 'code' : 'password',
        {
          message: error.message,
        }
      );
    }
  };

  return (
    <Modal
      title="Forgot Password"
      onDismiss={() => history.push({ search: '' })}
    >
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Icon name="logo" />
        </div>
        <form onSubmit={handleSubmit(!resetting ? sendVerification : reset)}>
          <InputRow label="Username" errorText={errors.username?.message}>
            <Input
              id="Username"
              autoComplete="username"
              autoFocus
              {...register('username', { required: 'Required' })}
            />
          </InputRow>
          {resetting ? (
            <>
              <AlertBox
                type="warning"
                message="You should receive an email with your verification code shortly."
              />
              <InputRow
                label="Verification code"
                errorText={errors.code?.message}
              >
                <Input
                  id="Verification code"
                  autoComplete="off"
                  {...register('code', { required: 'Required' })}
                />
              </InputRow>
              <InputRow
                label="New password"
                errorText={errors.password?.message}
              >
                <Input
                  id="New password"
                  type="password"
                  autoComplete="new-password"
                  {...register('password', { required: 'Required' })}
                />
              </InputRow>
            </>
          ) : null}
          <FormActions>
            <Button type="submit" disabled={isSubmitting}>
              Reset Password
            </Button>
          </FormActions>
        </form>
        <span className={styles.disclaimer}>
          <Link to={{ search: 'action=signup' }}>
            Don&apos;t have an account?
          </Link>
        </span>
      </div>
    </Modal>
  );
}
