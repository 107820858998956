import { CognitoUser } from '@aws-amplify/auth';
import axios, { AxiosInstance } from 'axios';
import { createContext, Dispatch, SetStateAction } from 'react';
import { Account } from '../../types/models';

const AppStateContext = createContext<{
  currentUser: (CognitoUser & { attributes?: any }) | undefined;
  setCurrentUser: Dispatch<SetStateAction<CognitoUser | undefined>>;
  axios: AxiosInstance;
  accounts?: Account[];
  showSettings: boolean;
  setShowSettings: Dispatch<SetStateAction<boolean>>;
}>({
  currentUser: undefined,
  setCurrentUser: () => {},
  axios: axios.create(),
  showSettings: false,
  setShowSettings: () => false,
});

export default AppStateContext;
