import Container from '../../components/Container/Container';
import styles from './EMLDisclaimer.module.css';

export default function EMLDisclaimer() {
  return (
    <div className={styles.container}>
      <Container>
        <h2>EML Payment Solutions Australia - Target Market Determination</h2>
        <hr />

        <h3>Introduction</h3>
        <p>
          EML Payment Solutions Limited (referred to as <strong>EML</strong>,
          us, our or we) are a product issuer and we issue non-cash payment
          facilities in the form of prepaid cards through the payment schemes
          (such as Mastercard and Visa). We are authorised and regulated by the
          Australian Securities and Investments Commission (ASIC), with licence
          number 404131 and registered office located at Level 12, 333 Ann
          Street Brisbane QLD 4000.
        </p>
        <p>
          The design and distribution obligations set out in Part 7.8A of the
          Corporations Act 2001 (<strong>DD Obligations</strong>) apply to the
          issue and distribution of non-cash payment facilities to retail
          clients (<strong>Consumers</strong>). Non-cash payment products are
          products that allow Consumers to make payments, otherwise than through
          the physical delivery of Australian or foreign currency (e.g. prepaid
          cards).
        </p>
        <p>
          The purpose of this target market determination (<strong>TMD</strong>)
          is to describe the target market of Consumers for our prepaid Cards to
          which the DD Obligations apply.
        </p>
        <p>
          If you are a retail client, you should refer to the relevant Product
          Disclaimer Statement (PDS) before deciding whether to acquire or
          continue to hold the relevant product. You can get a copy of the
          relevant PDS from the website of the distributor of the product
          subject to this TMD.
        </p>
        <p>
          You should not base any decision to transact on the contents of this
          TMD.
        </p>

        <h3>Product Information</h3>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Product</th>
              <th>Bokies Card prepaid Mastercard</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Product Options</th>
              <td>Bookies Card</td>
            </tr>
            <tr>
              <th>Issuer</th>
              <td>EML Payment Solutions Limited</td>
            </tr>
            <tr>
              <th>Start Date</th>
              <td>5 October 2021</td>
            </tr>
          </tbody>
        </table>

        <h3>Target Market</h3>
        <h4>Class of Consumer</h4>
        <p>
          <u>Likely objectives and needs</u>
        </p>
        <p>A Consumer:</p>
        <ul>
          <li>
            with a verified online bookmaking account with the distributor; and
          </li>
          <li>
            who may be seeking a reloadable prepaid Card to load confirmed
            winnings from their online bookmaking account to a prepaid card
            instead of a bank account, allowing the Consumer to purchase
            eligible goods and services immediately upon loading of the Card
            wherever Mastercard is accepted.
          </li>
        </ul>
        <p>
          <ul>Likely financial situation</ul>
        </p>
        <p>
          A Consumer who has confirmed winnings within their online bookmaking
          account, available in Australian dollars, to preload the Card and to
          pay any fees associated with the use of the Card
        </p>
        <h4>Product Description</h4>
        <p>
          A reloadable prepaid Mastercard with the following key attributes:
        </p>
        <ul>
          <li>
            ability to purchase eligible goods and services electronically where
            Mastercard is accepted using value loaded onto the Card; and
          </li>
          <li>
            a requirement to preload the Card and pay fees (including foreign
            exchange fees).
          </li>
        </ul>
        <p>It is only available to Consumers who:</p>
        <ul>
          <li>
            have a registered account with the online bookmaker who is the
            distributor of the Card; and
          </li>
          <li>
            who are subject to the terms and conditions of the online bookmaker
            in respect of the placing of wagers and the payment of winnings for
            successful wagers.
          </li>
        </ul>
        <h4>Appropriateness</h4>
        <p>
          The product (including its key attributes) is consistent with the
          objectives, financial situation and needs of Consumers, as it provides
          the means to access winnings from an online bookmaking account held
          with the distributor without the need to transfer to a Consumer’s bank
          account.
        </p>

        <h3>Distribution Conditions</h3>
        <h4>Marketing and Promotion</h4>
        <p>A distributor must only market and promote the product through:</p>
        <ul>
          <li>
            advertising through media (including social media), physical
            marketing materials (such as banners, brochures or flyers) and any
            other marketing material available to the general public; and
          </li>
          <li>
            any other EML approved communication channels (including telephone,
            email and social media).
          </li>
        </ul>
        <p>
          This condition is appropriate as the target market for obtaining an
          online bookmaking account through which the product is available is
          wide.
        </p>

        <h4>Retail Product Distribution Conduct</h4>
        <p>
          A distributor must only engage in retail product distribution conduct
          (other than general advice):
        </p>
        <ul>
          <li>
            only to Consumers who have a verified online bookmaking account with
            the distributor; and
          </li>
          <li>
            only through:
            <ul>
              <li>
                the distributor’s digital platform (such as their website or
                mobile app) and other EML approved third party digital
                platforms; and
              </li>
              <li>
                any other EML approved communication channels (including
                telephone, email and social media).
              </li>
            </ul>
          </li>
        </ul>
        <p>
          This condition is appropriate as the target market is limited to those
          who have an online bookmaking account with the distributor, and it is
          the most appropriate method for Consumers within the target market to
          obtain the product. Such conduct poses limited risk to Consumers.
        </p>

        <h3>Review Triggers</h3>
        <p>
          EML, and the distributor of this product, must cease retail product
          distribution conduct in respect of this product as soon as
          practicable, but no later than 10 business days after EML determines a
          material event or circumstance has occurred in relation to the
          following:
        </p>
        <table className={styles.table}>
          <tbody>
            <tr>
              <th>Material Complaints</th>
              <td>
                material complaints (in number or significance) received by EML
                or the distributor in relation to the terms of this product and
                / or the distribution conduct.
              </td>
            </tr>
            <tr>
              <th>Product Performance</th>
              <td>
                evidence, as determined by EML, of the performance of the
                product, in practice, that may suggest that the product is not
                appropriate for the target market.
              </td>
            </tr>
            <tr>
              <th>Distributor Feedback</th>
              <td>
                reporting from the distributor, or consistent feedback from the
                distributor on the target market which suggests that the
                determination may no longer be appropriate.
              </td>
            </tr>
            <tr>
              <th>Substantial Product Change</th>
              <td>
                a substantial change to the product that is likely to result in
                the determination no longer being appropriate for the target
                market.
              </td>
            </tr>
            <tr>
              <th>Significant Dealing</th>
              <td>
                a material pattern of dealings in the product or of distributor
                conduct that is not consistent with the determination
              </td>
            </tr>
            <tr>
              <th>Notification from ASIC</th>
              <td>
                a notification from ASIC requiring immediate cessation of
                product distribution or particular conduct in relation to the
                product.
              </td>
            </tr>
            <tr>
              <th>Review Period</th>
              <td>
                The first review, and each ongoing review, must be completed
                within each consecutive 12 month periods from the Start Date.
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Reporting Information</h3>
        <p>
          A distributor that engages in retail product distribution conduct in
          respect of this product must provide the following information in
          writing to EML within 10 business days after the end of each reporting
          period unless indicated otherwise below:
        </p>
        <table className={styles.table}>
          <tbody>
            <tr>
              <th>Complaint Information</th>
              <td>
                Information about complaints received in relation to the product
                during the reporting period, and if complaints were received, a
                description of the number of complaints and the nature of the
                complaints received and other complaint information set out in
                paragraph RG 271.182 of Regulatory Guide 271 Internal dispute
                resolution.
              </td>
            </tr>
            <tr>
              <th>Distributor Feedback</th>
              <td>
                Information discovered or held by the distributor that suggests
                that the determination may no longer be appropriate.
              </td>
            </tr>
            <tr>
              <th>Significant Dealing</th>
              <td>
                Information about any significant dealing in the product that is
                not consistent with the target market determination of which the
                distributor becomes aware. <br />
                The distributor must provide the information as soon as
                practicable, or in any event, within 10 business days after
                becoming aware of the significant dealing.
              </td>
            </tr>
            <tr>
              <th>Information Requested by EML</th>
              <td>
                Information reasonably requested by the EML.
                <br />
                The distributor must provide the information as soon as
                practicable and no later than the date specified by the EML.
              </td>
            </tr>
            <tr>
              <th>Reporting Period</th>
              <td>
                The reporting period for this determination is every 6 months
                commencing from the Start Date.
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Other Information</h3>
        <p>
          EML reserves the right to amend the TMD at any time if such amendment
          is needed as a result of any changes to the law or regulations,
          regulatory guidance or for any reason EML considers as a proper reason
          to amend the TMD.
        </p>

        <p>Last update: 24th September 2021</p>
      </Container>
    </div>
  );
}
