import Auth, { CognitoUser } from '@aws-amplify/auth';
import axios from 'axios';
import QueryString from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Account } from '../../types/models';
import AppStateContext from './AppStateContext';

export default function AppStateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentUser, setCurrentUser] = useState<CognitoUser>();
  const [ready, setReady] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    instance.interceptors.request.use(async (config) => {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${idToken.getJwtToken()}`,
        },
        url: `${config.url}${QueryString.stringify(
          { username: idToken.payload['cognito:username'] },
          { addQueryPrefix: true }
        )}`,
      };
    });

    return instance;
  }, []);
  const accounts = useQuery<Account[]>(
    'Accounts',
    async () => {
      const response = await axiosInstance.get('/v1/accounts');
      return response.data;
    },
    {
      enabled: !!currentUser,
    }
  );

  useEffect(() => {
    async function checkAuth() {
      try {
        const user: CognitoUser = await Auth.currentAuthenticatedUser({
          bypassCache: false,
        });
        setCurrentUser(user);
        setReady(true);
      } catch (error) {
        setReady(true);
      }
    }

    checkAuth();
  }, []);

  if (!ready || accounts.isLoading) return null;

  return (
    <AppStateContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        axios: axiosInstance,
        accounts: accounts.data,
        showSettings,
        setShowSettings,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
}
