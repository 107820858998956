import styles from './InputRow.module.css';

type Props = {
  label: string;
  children: React.ReactNode;
  errorText?: string;
  hintText?: string;
};

export default function InputRow({
  label,
  children,
  errorText,
  hintText,
}: Props) {
  return (
    <div className={styles.container}>
      <label htmlFor={label} className={styles.label}>
        {label}
      </label>
      <div className={styles.row}>{children}</div>
      {errorText ? <span className={styles.errorText}>{errorText}</span> : null}
      {hintText ? <span className={styles.hintText}>{hintText}</span> : null}
    </div>
  );
}
