import Auth from '@aws-amplify/auth';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import AlertBox from '../../../components/AlertBox/AlertBox';
import Button from '../../../components/Button/Button';
import FormActions from '../../../components/Form/FormActions';
import Input from '../../../components/Form/Input';
import InputRow from '../../../components/Form/InputRow';
import SignupContext, { SignupForm } from './SignupContext';

export default function AccountDetails() {
  const { data, onComplete } = useContext(SignupContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignupForm>();
  const [errorMessage, setErrorMessage] = useState('');

  const submitHandler: SubmitHandler<SignupForm> = async (formValues) => {
    try {
      await Auth.signUp({
        username: formValues.username || '',
        password: formValues.password || '',
        attributes: {
          email: data?.email,
          given_name: data?.firstName,
          family_name: data?.lastName,
          address: JSON.stringify({
            streetName: data?.address,
            suburb: data?.suburb,
            state: data?.state,
            postcode: data?.postcode,
            country: 'AUS',
          }),
          birthdate: dayjs(data?.dateOfBirth).format('YYYY-MM-DD'),
          gender: 'male', // TODO: hardcoded for now as it is a required field
          phone_number: `+61${data?.mobile}`,
        },
      });
      onComplete?.();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <InputRow label="Username" errorText={errors.username?.message}>
        <Input
          id="Username"
          placeholder="Enter your username"
          autoComplete="username"
          autoFocus
          {...register('username', { required: 'Required' })}
        />
      </InputRow>
      <InputRow
        label="Password"
        errorText={errors.password?.message}
        hintText="Must have 8 characters, including upper & lower case, numbers & special characters"
      >
        <Input
          id="Password"
          placeholder="Enter your password"
          type="password"
          autoComplete="new-password"
          {...register('password', { required: 'Required' })}
        />
      </InputRow>
      {errorMessage ? <AlertBox type="error" message={errorMessage} /> : null}
      <FormActions>
        <Button type="submit" disabled={isSubmitting}>
          Sign up
        </Button>
      </FormActions>
    </form>
  );
}
