import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import Card from '../../../components/Card/Card';
import useAppState from '../../../hooks/useAppState/useAppState';
import bookieDetail from '../../../utils/bookieDetail';
import BookmakerSignup from './BookmakerSignup/BookmakerSignup';
import BookmakerLink from './BookmakerLink/BookmakerLink';
import styles from './AvailableBookmakers.module.css';
import AccountContext from '../AccountContext';

type Bookie = {
  id: string;
  name: string;
  providerIds: string[];
};

export default function AvailableBookmakers() {
  const { axios } = useAppState();
  const [linkForBookie, setLinkForBookie] = useState<Bookie>();
  const [signupForBookie, setSignupForBookie] = useState<Bookie>();
  const { myBookies } = useContext(AccountContext);
  const bookies = useQuery<Bookie[]>('Bookies', async () => {
    const response = await axios.get('/v1/bookies');
    return response.data;
  });

  return (
    <>
      <h2>Sign up / Link Bookmakers</h2>
      <div className={styles.list}>
        {bookies.data
          ?.filter(
            (bookie) => !myBookies.map((b) => b.bookieId).includes(bookie.id)
          )
          .map((bookie) => {
            const bookieMap = bookieDetail[bookie.id];

            return (
              <Card key={bookie.id}>
                <div className={styles.bookie}>
                  <div className={styles.bookieDetail}>
                    <img src={bookieMap.logo} alt={bookieMap.name} />
                    <span className={styles.name}>{bookieMap.name}</span>
                  </div>
                  <div className={styles.actions}>
                    <button
                      type="button"
                      className={styles.button}
                      onClick={() => setLinkForBookie(bookie)}
                    >
                      Link
                    </button>
                    <button
                      type="button"
                      className={styles.button}
                      onClick={() => setSignupForBookie(bookie)}
                    >
                      Sign up
                    </button>
                  </div>
                </div>
              </Card>
            );
          })}
      </div>
      {linkForBookie ? (
        <BookmakerLink
          bookieId={linkForBookie.id}
          bookieDetail={bookieDetail[linkForBookie.id]}
          onDismiss={() => setLinkForBookie(undefined)}
        />
      ) : null}
      {signupForBookie ? (
        <BookmakerSignup
          bookieId={signupForBookie.id}
          bookieDetail={bookieDetail[signupForBookie.id]}
          onDismiss={() => setSignupForBookie(undefined)}
        />
      ) : null}
    </>
  );
}
