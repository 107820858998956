import { useContext } from 'react';
import format from '../../../utils/format';
import useAppState from '../../../hooks/useAppState/useAppState';
import styles from './Summary.module.css';
import AccountContext from '../AccountContext';

export default function Summary() {
  const { accounts } = useAppState();
  const { cards } = useContext(AccountContext);

  const bookiesCardBalance = accounts?.reduce(
    (total, value) => total + value.balance,
    0
  );
  const availableCardBalance = cards?.reduce(
    (total, value) => total + value.balance,
    0
  );

  return (
    <>
      <h2>Summary</h2>
      <div className={styles.content}>
        <div className={styles.imgContainer}>
          <img
            src="/bookiescard-straight.png"
            alt="Card"
            className={styles.img}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.flex}>
            <div className={styles.section}>
              <span className={styles.title}>Bookies Card Balance</span>
              <span className={styles.value}>
                {format.currency(bookiesCardBalance)}
              </span>
            </div>

            <div className={styles.section}>
              <span className={styles.title}>Available</span>
              <span className={styles.value}>
                {format.currency(availableCardBalance)}
              </span>
            </div>
          </div>
          <div className={styles.flex}>
            <div className={styles.section}>
              <span className={styles.title}>Bookmakers Balance</span>
              <span className={styles.value}>N/A</span>
            </div>
            <div className={styles.section}>
              <span className={styles.title}>Available</span>
              <span className={styles.value}>N/A</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
