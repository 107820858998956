import { createContext, Dispatch, SetStateAction } from 'react';

export type SignupForm = {
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: string;
  address?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
  mobile?: string;
  username?: string;
  password?: string;
};

export type SignupFormContext = {
  data?: SignupForm;
  setData?: Dispatch<SetStateAction<SignupForm>>;
  onComplete?: () => void;
};

const SignupContext = createContext<SignupFormContext>({});

export default SignupContext;
