function formatCurrency(value = 0, minimumFractionDigits = 0) {
  const sanitised = parseFloat(String(value || 0).replace(/[^-\d.]/g, ''));

  return new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    maximumFractionDigits: 2,
    minimumFractionDigits,
  }).format(sanitised);
}

const format = {
  currency: formatCurrency,
};

export default format;
