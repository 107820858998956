import * as IconList from './icons';

export type IconName = keyof typeof IconList;

interface Props {
  name: IconName;
  className?: string;
}

export const ListOfIcons = Object.keys(IconList);

export default function Icon({ name, className = '', ...rest }: Props) {
  const IconComponent = IconList[name] || IconList.cross;

  return <IconComponent className={className} {...rest} />;
}
