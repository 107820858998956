import clsx from 'clsx';
import styles from './Button.module.css';

type ButtonTheme = 'default' | 'secondary' | 'none';
type ButtonType = 'button' | 'submit' | 'reset';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: ButtonType;
  theme?: ButtonTheme;
  contentClassName?: string;
}

export default function Button({
  type = 'button',
  theme = 'default',
  className = '',
  contentClassName = '',
  children,
  ...rest
}: ButtonProps) {
  return (
    <button
      /* eslint-disable react/button-has-type */
      type={type}
      /* eslint-enable react/button-has-type */
      className={clsx(styles.button, styles[`theme--${theme}`], className)}
      {...rest}
    >
      <span className={clsx(styles.buttonContent, contentClassName)}>
        {children}
      </span>
    </button>
  );
}
