import { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { v4 } from 'uuid';
import useAppState from '../../../hooks/useAppState/useAppState';
import InputRow from '../../../components/Form/InputRow';
import Select from '../../../components/Form/Select';
import Input from '../../../components/Form/Input';
import format from '../../../utils/format';
import bookieDetail from '../../../utils/bookieDetail';
import AlertBox from '../../../components/AlertBox/AlertBox';
import Button from '../../../components/Button/Button';
import FormActions from '../../../components/Form/FormActions';
import styles from './Transfer.module.css';
import AccountContext from '../AccountContext';

type FormValues = {
  from: string;
  to: string;
  amount: string;
};

export default function Transfer() {
  const { myBookies } = useContext(AccountContext);
  const { accounts, axios } = useAppState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>({
    defaultValues: {
      from: accounts?.[0]?.id,
    },
  });
  const transferMutation = useMutation<
    unknown,
    {
      response: {
        data: {
          error: {
            errorNumber: number;
            message: string;
          };
        };
      };
    },
    FormValues
  >(
    async (data) => {
      await axios.post(`/v1/accounts/${data.from}/transfer`, {
        tid: v4(),
        amount: +data.amount,
        bookieId: data.to,
      });
    },
    {
      onError: (error) => {
        const { message } = error.response.data.error;
        setError('amount', { message });
        toast.error('Something went wrong with your transfer.');
      },
    }
  );

  const transfer: SubmitHandler<FormValues> = async (data) => {
    await transferMutation.mutate(data);
    toast.info(
      `We are processing your transfer to ${bookieDetail[data.to].name}`
    );
  };

  return (
    <>
      <h2>Transfer Funds</h2>
      {myBookies.length === 0 ? (
        <AlertBox
          type="warning"
          message="You don't have any linked bookmakers. Link a bookmaker first."
          className={styles.alert}
        />
      ) : (
        <form className={styles.content} onSubmit={handleSubmit(transfer)}>
          <InputRow label="From" errorText={errors.from?.message}>
            <Select
              id="From"
              options={
                accounts?.map((account) => ({
                  label: `${account.ownerName} (${format.currency(
                    account.balance
                  )})`,
                  value: account.id,
                })) || []
              }
              {...register('from', { required: 'Required' })}
            />
          </InputRow>
          <InputRow label="To" errorText={errors.to?.message}>
            <Select
              id="To"
              options={myBookies.map((bookie) => ({
                label: bookieDetail[bookie.bookieId].name,
                value: bookie.bookieId,
              }))}
              {...register('to', { required: 'Required' })}
            />
          </InputRow>
          <InputRow label="Amount" errorText={errors.amount?.message}>
            <Input
              type="number"
              min={0}
              {...register('amount', {
                required: 'Required',
                min: {
                  value: 1,
                  message: `Minimum deposit amount is ${format.currency(1)}`,
                },
              })}
            />
          </InputRow>
          <FormActions>
            <Button type="submit" disabled={transferMutation.isLoading}>
              Transfer
            </Button>
          </FormActions>
        </form>
      )}
    </>
  );
}
