import { useState } from 'react';
import {
  Link,
  MemoryRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal/Modal';
import PersonalDetails from './PersonalDetails';
import AddressDetails from './AddressDetails';
import AccountDetails from './AccountDetails';
import SignupProgress from './SignupProgress';
import SignupContext, { SignupForm } from './SignupContext';
import styles from './SignupModal.module.css';
import Icon from '../../../components/Icon/Icon';

export default function SignupModal() {
  const history = useHistory();
  const [data, setData] = useState<SignupForm>({});

  function onComplete() {
    toast.success('Your account has been created. Please verify your email.');
    history.push('/');
  }

  return (
    <SignupContext.Provider
      value={{
        data,
        setData,
        onComplete,
      }}
    >
      <Modal title="Sign up" onDismiss={() => history.push({ search: '' })}>
        <div className={styles.container}>
          <Icon name="logo" className={styles.logo} />
          <MemoryRouter>
            <>
              <SignupProgress />
              <Switch>
                <Route exact path="/" component={PersonalDetails} />
                <Route exact path="/address" component={AddressDetails} />
                <Route exact path="/account" component={AccountDetails} />
                <Redirect to="/" />
              </Switch>
            </>
          </MemoryRouter>
          <span className={styles.disclaimer}>
            Have an account? <Link to={{ search: 'action=login' }}>Login</Link>{' '}
            or <Link to={{ search: 'action=reset' }}>Reset your password</Link>
          </span>
        </div>
      </Modal>
    </SignupContext.Provider>
  );
}
