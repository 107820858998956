import Auth from '@aws-amplify/auth';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './styles/variables.css';
import './styles/globals.css';
import reportWebVitals from './utils/reportWebVitals';
import App from './pages/App/App';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import EMLDisclaimer from './pages/EMLDisclaimer/EMLDisclaimer';

Auth.configure({
  mandatorySignIn: true,
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
});

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route exact path="/view/privacy-policy" component={PrivacyPolicy} />
          <Route
            exact
            path="/view/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route exact path="/view/eml-disclaimer" component={EMLDisclaimer} />
          <Route component={App} />
        </Switch>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
