import clsx from 'clsx';
import { forwardRef } from 'react';
import styles from './Select.module.css';

type SelectProps = React.InputHTMLAttributes<HTMLSelectElement> & {
  options: {
    label: string;
    value: string;
  }[];
};

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ className = '', options = [], ...rest }, ref) => (
    <select className={clsx(styles.select, className)} ref={ref} {...rest}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  )
);

export default Select;
