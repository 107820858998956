import clsx from 'clsx';
import QueryString from 'qs';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button/Button';
import styles from './Home.module.css';
import heroImg from './hero-image.png';
import cardImg from './card-angled.png';
import iphoneImg from './iphone.png';
import Accordion from '../../components/Accordion/Accordion';
import useAppState from '../../hooks/useAppState/useAppState';
import Container from '../../components/Container/Container';

export default function Home() {
  const history = useHistory();
  const { currentUser } = useAppState();

  useEffect(() => {
    if (currentUser) {
      history.push('/account');
    }
  }, [currentUser, history]);

  return (
    <>
      <section
        className={clsx(styles.section, styles.hero)}
        style={{
          backgroundImage: `url(${heroImg})`,
        }}
      >
        <Container>
          <div className={styles.row}>
            <div className={styles.imgContainer}>
              <img src={cardImg} alt="Card" className={styles.img} />
            </div>
            <div className={styles.content}>
              <h1 className={styles.title}>
                Easily access your winnings from all your bookmakers
              </h1>
              <p>
                With the Bookies Card, you can easily transfer funds from one
                bookmaker to another and use your Bookies Card to spend your
                winnings.
              </p>
              {localStorage.getItem('qa') === 'true' ? (
                <Button
                  theme="secondary"
                  onClick={() =>
                    history.push({
                      search: QueryString.stringify({ action: 'signup' }),
                    })
                  }
                  className={styles.ctaButton}
                >
                  Sign up for a Bookies Card
                </Button>
              ) : null}
            </div>
          </div>
        </Container>
      </section>
      <section className={styles.bookies}>
        <Container>
          <div className={styles.bookiesList}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://robwaterhouse.com/racing"
            >
              <img
                src="/bookies/rwat.png"
                alt="Bet Deluxe"
                className={styles.bookieLogo}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://realbookie.com.au/racing"
            >
              <img
                src="/bookies/realbookie.png"
                alt="Bet Deluxe"
                className={styles.bookieLogo}
              />
            </a>
          </div>
        </Container>
      </section>
      <section className={clsx(styles.section, styles.dark)}>
        <Container>
          <div className={styles.row}>
            <div className={styles.content}>
              <h2>How it works</h2>
              <ol className={styles.list}>
                <li>
                  <h3>Sign Up</h3>
                  <p>Create a Bookies Card account to order your card.</p>
                </li>
                <li>
                  <h3>Link Accounts</h3>
                  <p>
                    Link your existing bookmaker accounts to start accessing
                    your winnings.
                  </p>
                </li>
                <li>
                  <h3>Begin Transferring funds</h3>
                  <p>
                    Transfer winnings from your bookmakers account to your
                    Bookies Card as well as transferring winnings from you
                    Bookies Card to any of your linked bookmaker accounts.
                  </p>
                </li>
                <li>
                  <h3>Access your winnings</h3>
                  <p>
                    Use your Bookies Card at ATMs, shop online or buy in person.
                  </p>
                </li>
              </ol>
            </div>
            <div className={styles.imgContainer}>
              <img src={iphoneImg} alt="iPhone App" className={styles.img} />
            </div>
          </div>
        </Container>
      </section>
      <section className={clsx(styles.section, styles.faq)}>
        <Container>
          <h2>FAQs</h2>

          <Accordion
            items={[
              {
                title: 'Will I be charged any fees?',
                children:
                  'You won’t be charged any fees to transfer funds between your Bookies Card and Bookmaker accounts.',
              },
              {
                title: 'How long until I receive my card?',
                children:
                  'It can take between 5 and 10 working days for your Bookies Card to arrive at your provided address.',
              },
              {
                title: 'What happens if I lose my card?',
                children:
                  'You can deactivate your Bookies Card via the website or via the Bookmakers app where you have Bookies Card enabled. This will instantly prevent your Bookies card from being active to prevent any further usage.',
              },
              {
                title: 'Which bookmakers accept Bookies Card?',
                children:
                  'The Bookmakers available for Bookies Card are Rob Waterhouse and Real Bookie with more Bookmakers continually being added.',
              },
            ]}
          />
        </Container>
      </section>
    </>
  );
}
